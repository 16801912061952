function goToDetail(id){
    window.location= "./facility/"+id;
}


function searchFacility_old(){
    searchTxt = document.getElementById("mapSearchField").value;
    alert("Search Text is: " + searchTxt);
    console.log ();
}


function checkAqProgram (value, key, data) {
    var aqp = data.isRegByAirQuality == 1 ? "<img title='Air Quality Program' src='assets/images/air_quality_icon_blue.png' class='programImage'/>" : "<img title='Air Quality Program' src='assets/images/air_quality_icon.png' class='grayImage' />";
    return aqp;
}
function checkSwProgram (value, key, data) {
    var swlp = data.isRegBySolidWaste == 1 ? "<img title='Solid Waste Landfill Program' src='assets/images/solid_waste_icon_blue.png' class='programImage'/>" : "<img title='Solid Waste Landfill Program' src='assets/images/solid_waste_icon.png' class='grayImage' />";
    return swlp;
}
function checkUstProgram (value, key, data) {
    var ust = data.isRegByUST == 1 ? "<img title='Underground Storage Tank (UST)' src='assets/images/underground_storage_icon_blue.png' class='programImage'/>" : "<img title='Underground Storage Tank (UST)' src='assets/images/underground_storage_icon.png' class='grayImage' />";
    return ust;
}
function checkUicProgram (value, key, data) {
    var uic = data.isRegByUIC == 1 ? "<img title='Underground Injection Control (UIC)' src='assets/images/underground_injection_blue.png' class='programImage'/>" : "<img title='Underground Injection Control (UIC)' src='assets/images/underground_injection.png' class='grayImage' />";
    return uic;
}
function checkPdesProgram (value, key, data) {
    var pdes = data.isRegByPDES == 1 ? "<img title='Pollution Discharge Elimination System (PDES)' src='assets/images/pollution_discharge_icon_blue.png' class='programImage'/>" : "<img title='Pollution Discharge Elimination System (PDES)' src='assets/images/pollution_discharge_icon.png' class='grayImage' />";
    return pdes;
}
  //{isRegByAirQuality:checkAqProgram}{isRegBySolidWaste:checkSwProgram}{isRegByUST:checkUstProgram}{isRegByUIC:checkUicProgram}{isRegByPDES:checkPdesProgram}



